import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import YouTube from "react-youtube"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/games.scss"
import "../../styles/game-page.scss"

// Images
import bg from "../../images/data/raji/meta/bg.jpg"

import keyart from "../../images/data/raji/hero/keyart.jpg"
import keyart_thumbnail from "../../images/data/raji/hero/keyart-thumbnail.jpg"
import gameplay_thumbnail from "../../images/data/raji/hero/gameplay-thumbnail.jpg"

import game_about from "../../images/data/raji/meta/icon.jpg"
import game_logo from "../../images/data/raji/meta/logo.png"
import game_developer from "../../images/data/raji/meta/developer.png"

import slide_1 from "../../images/data/raji/slider/a.jpg"
import slide_2 from "../../images/data/raji/slider/b.jpg"
import slide_3 from "../../images/data/raji/slider/c.jpg"
import slide_4 from "../../images/data/raji/slider/d.jpg"
import slide_5 from "../../images/data/raji/slider/e.jpg"
import slide_6 from "../../images/data/raji/slider/f.jpg"
import slide_7 from "../../images/data/raji/slider/g.jpg"
import slide_8 from "../../images/data/raji/slider/h.jpg"
import slide_9 from "../../images/data/raji/slider/i.jpg"
import slide_10 from "../../images/data/raji/slider/j.jpg"

import award_1 from "../../images/data/raji/awards/1.png"
import award_2 from "../../images/data/raji/awards/2.png"
import award_3 from "../../images/data/raji/awards/3.png"
import award_4 from "../../images/data/raji/awards/4.png"
import award_5 from "../../images/data/raji/awards/5.png"
import award_6 from "../../images/data/raji/awards/6.png"

import press1 from "../../images/data/raji/press/1.png"

import thumbnail_1 from "../../images/data/wardens/thumbnail.jpg"
import thumbnail_2 from "../../images/data/metaphora/thumbnail.jpg"
import thumbnail_3 from "../../images/data/broken-lines/thumbnail.jpg"

import game_logo_1 from "../../images/data/wardens/meta/logo.png"
import game_logo_2 from "../../images/data/metaphora/meta/logo.png"
import game_logo_3 from "../../images/data/broken-lines/meta/logo.png"

// Videos
import video from "../../videos/raji.mp4"

const GamePage = () => {

  return (
    <Layout>

      <Helmet>
        <body className="game-page"/>
      </Helmet>

      <SEO title="RAJI AN ANCIENT EPIC"/>
      <Link to="/" className="logo" activeClassName="-active">Super!com</Link>
      <Link to="/games/" className="title">Games</Link>

      <div className="games">

        <div className="center">

          <section className="games__card -alone">

            <div className="games__card__bg" style={{backgroundImage: 'url(' + bg + ')'}}></div>
            
            <h3 className="games__card__title">
              <span className="games__card__title__link">
                RAJI: <br/>
                AN ANCIENT EPIC
                <span className="games__card__title__link__released">
                  <span>Q3 2020</span>
                </span>
              </span>
            </h3>

            <div className="games__card__content">
              <div className="games__card__video">
                <div className="games__card__video__visible">
                  <img src={keyart} className="games__card__video__visible__size-setter"  alt=""/>
                  <div className="games__card__video__visible__item" data-id="keyart">
                    <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                      <source src={video} type="video/mp4"/>
                    </video>
                    <img src={keyart} className="games__card__video__visible__item__media"  alt=""/>
                  </div>
                  <div className="games__card__video__visible__item" data-id="gameplay">
                    <YouTube
                      videoId="X69LkDDOk-w"
                      opts={{
                        playerVars: {
                          controls: 0,
                          playlist: 'X69LkDDOk-w',
                          loop: 1,
                          showinfo: 0,
                          autoplay: 1,
                          start: 0,
                          mute: 1,
                          enablejsapi: 1
                        }
                      }}
                      // onReady={_onReady}
                    ></YouTube>
                  </div>
                </div>
                <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + keyart_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Key Art</span>
                </button>
                <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + gameplay_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Gameplay</span>
                </button>
              </div>
            </div>

            <footer className="games__card__footer">
              <div className="games__card__footer__about">
                <div className="games__card__footer__about__image">
                  <img src={game_about} alt="Raji"/>
                </div>
                <div className="games__card__footer__about__text">
                  Raji: An Anсient Epic is an action-adventure game set in
                  ancient India. Survive the demonic invasion as Raji, a young
                  and agile street performer, who uses the blessings of the gods
                  to unlock mysterious powers and rescue her little brother
                  who has been kidnapped by the forces of evil.
                  <div className="games__card__footer__about__text__social">
                    <a href="https://www.youtube.com/channel/UCo0t8PwZ4uUdlKYzG_NXb7g" className="games__card__footer__about__text__social__item -youtube" target="_blank" rel="noopener noreferrer">youtube</a>
                    <a href="https://www.facebook.com/rajithegame/" className="games__card__footer__about__text__social__item -facebook" target="_blank" rel="noopener noreferrer">facebook</a>
                    <a href="https://twitter.com/RajitheGame" className="games__card__footer__about__text__social__item -twitter" target="_blank" rel="noopener noreferrer">twitter</a>
                    <a href="https://www.instagram.com/rajithegame/" className="games__card__footer__about__text__social__item -instagram" target="_blank" rel="noopener noreferrer">instagram</a>
                    <a href="https://discordapp.com/invite/f9gnFVP" className="games__card__footer__about__text__social__item -discord" target="_blank" rel="noopener noreferrer">discord</a>
                    <a href="https://www.reddit.com/r/rajithegame/" className="games__card__footer__about__text__social__item -reddit" target="_blank" rel="noopener noreferrer">reddit</a>
                  </div>
                  <div className="games__card__footer__about__text__developer">
                    <div className="games__card__footer__about__text__developer__label">Game developer</div>
                    <a href="https://www.noddingheadsgames.com/" className="games__card__footer__about__text__developer__logo" target="_blank" rel="noopener noreferrer">
                      <img src={game_developer} alt=""/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="games__card__footer__stores">
                <div className="games__card__footer__stores__line">
                  <a href="https://store.steampowered.com/app/730390/Raji_An_Ancient_Epic/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  <a className="games__card__footer__stores__line__item -switch"
                      data-america-url="https://www.nintendo.com/games/detail/raji-an-ancient-epic-switch/"
                      data-europe-url="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Raji-An-Ancient-Epic-1821392.html?s=countries"
                      target="_blank" rel="noopener noreferrer">switch</a>
                </div>
                <div className="games__card__footer__stores__line">
                  <a className="games__card__footer__stores__line__item -ps"
                      href="https://store.playstation.com/en-us/product/UP5729-CUSA20607_00-RAJIANANCIENTEPI"
                      data-europe-url="https://store.playstation.com/ru-ru/product/EP5911-CUSA20344_00-RAJIANANCIENTEPI"
                      target="_blank" rel="noopener noreferrer">ps</a>
                  <a href="https://www.microsoft.com/en-us/p/raji-an-ancient-epi%D1%81/9nh2j7x3mpb0" className="games__card__footer__stores__line__item -xbox" target="_blank" rel="noopener noreferrer">xbox</a>
                </div>
              </div>
              <a href="https://www.rajithegame.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                <img src={game_logo} className="games__card__footer__logo" alt="Raji"/>
              </a>
            </footer>
            
          </section>

        </div>

      </div>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Key <span className="game-section__title__text__accent">Features</span>
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__features">
              <div className="game-section__features__item">
                <b>Gorgeous hand-painted visuals</b> <br/>
                inspired by real-world locations
              </div>
              <div className="game-section__features__item">
                <b>Diverse weapons and powers</b> <br/>
                Each combination has its own combat <br/>
                style and tactical consequences
              </div>
              <div className="game-section__features__item">
                <b>Use advanced movements to <br/>
                your advantage</b>, both in combat <br/>
                and while traversing a level
              </div>
              <div className="game-section__features__item">
                A universe <b>based on Indian and <br/>
                Balinese mythology</b> created by <br/>
                the passionate native team
              </div>
            </div>

            <div className="game-section__slider -collapsed">
              <div className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    <li className="glide__slide">
                      <img src={slide_1} alt="" data-index="0"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_2} alt="" data-index="1"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_3} alt="" data-index="2"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_4} alt="" data-index="3"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_5} alt="" data-index="4"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_6} alt="" data-index="5"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_7} alt="" data-index="6"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_8} alt="" data-index="7"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_9} alt="" data-index="8"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_10} alt="" data-index="9"/>
                    </li>
                  </ul>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
                  <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Game <span className="game-section__title__text__accent">Trailer</span>
            </span>
          </h4>

          <div className="game-section__content -trailer">
            <YouTube
              videoId="OFc8hBeDCrA"
              opts={{
                playerVars: {
                  controls: 1,
                  playlist: 'OFc8hBeDCrA',
                  loop: 1,
                  showinfo: 0,
                  autoplay: 0,
                  start: 0,
                  mute: 1,
                  enablejsapi: 1
                }
              }}
              // onReady={_onReady}
            ></YouTube>
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Awards <span className="game-section__title__text__accent">We Are Proud Of</span>
            </span>
          </h4>

          <div className="game-section__content">
            <div className="game-section__awards">
              <div className="game-section__awards__item">
                <img src={award_1} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_2} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_3} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_4} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_5} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_6} alt=""/>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Press <span className="game-section__title__text__accent">About Us</span>
            </span>
          </h4>

          <div className="game-section__content">
            
            <div className="game-section__quotes-group">
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://www.eurogamer.net/articles/2020-09-02-raji-an-ancient-epic-review-a-gorgeous-adventure-through-architecture-and-myth" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  Raji: An Ancient Epic review - a gorgeous adventure through architecture and myth
                </Link>
              </div>
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://purenintendo.com/review-raji-an-ancient-epic-nintendo-switch/" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  REVIEW: RAJI: AN ANCIENT EPIC (NINTENDO SWITCH)
                </Link>
              </div>
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://kotaku.com/raji-an-ancient-epic-is-a-beautiful-exploration-of-lov-1844795124" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  Raji: An Ancient Epic Is A Beautiful Exploration Of Love And Gods
                </Link>
              </div>
            </div>

            <div className="game-section__image">
              <img src={press1} alt=""/>
            </div>
            
            <div className="game-section__quotes-group">
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://youtu.be/KHcEOnvkRkw" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  Ein ungewöhnliches Szenario - Raji: An Ancient Epic #gamescom2020
                </Link>
              </div>
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://www.ign.com/videos/raji-an-ancient-epic-gameplay-trailer-pits-you-against-a-terrifying-larger-than-life-boss" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  Raji: An Ancient Epic Gameplay Trailer Pits You Against a Terrifying, Larger Than Life Boss
                </Link>
              </div>
            </div>

            <div className="game-section__press-kit">
              <div className="game-section__press-kit__bg" style={{backgroundImage: 'url(' + bg + ')'}}></div>
              <a href="https://app.box.com/s/mn88jlxcif1fj99yvxn2icko0ownwdhj/folder/84973694546" className="game-section__press-kit__link" target="_blank" rel="noopener noreferrer">
                <span className="game-section__press-kit__link__accent-text">Press <br/>Kit</span>
                <span className="game-section__press-kit__link__text">Let's see</span>
              </a>
            </div>

            <button className="content__creator">
              <span className="content__creator__text">If you are content creator</span>
            </button>
            
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title -small">
            <i></i>
            <span className="game-section__title__text">
              You may like our other games
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__recents">
              <Link to="/games/alchemist-adventure/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_1 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>WARDENS THE LOST LEGACY</span>
                </span>
                <span className="game-section__recents__item__text">
                  Wardens: The Lost Legacy is a third-person action RPG where a mundane investigation brings detective Daniel Blake into a fantastical and magical world
                </span>
                <img src={game_logo_1} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/wardens/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_2 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>METAPHORA THE MOONY-CAT QUEST</span>
                </span>
                <span className="game-section__recents__item__text">
                  An animated action-adventure with catching
                  story about regenerating the world
                </span>
                <img src={game_logo_2} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/broken-lines/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_3 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>Broken Lines</span>
                </span>
                <span className="game-section__recents__item__text">
                  Broken Lines is a story-driven tactical turnbased
                  RPG set in an alternative WW2
                </span>
                <img src={game_logo_3} className="game-section__recents__item__image" alt=""/>
              </Link>
            </div>

            <div className="games__promo__button">
              <button className="games__promo__button__link" data-role="call-to-action">
                <span className="games__promo__button__link__text">Give a boost to your game</span>
              </button>
              <div className="games__promo__button__shadow"></div>
            </div>

          </div>

        </div>
      </section>

      <div className="game-section__slider -expanded">
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              <li className="glide__slide">
                <img src={slide_1} alt="" data-index="0"/>
              </li>
              <li className="glide__slide">
                <img src={slide_2} alt="" data-index="1"/>
              </li>
              <li className="glide__slide">
                <img src={slide_3} alt="" data-index="2"/>
              </li>
              <li className="glide__slide">
                <img src={slide_4} alt="" data-index="3"/>
              </li>
              <li className="glide__slide">
                <img src={slide_5} alt="" data-index="4"/>
              </li>
              <li className="glide__slide">
                <img src={slide_6} alt="" data-index="5"/>
              </li>
              <li className="glide__slide">
                <img src={slide_7} alt="" data-index="6"/>
              </li>
              <li className="glide__slide">
                <img src={slide_8} alt="" data-index="7"/>
              </li>
              <li className="glide__slide">
                <img src={slide_9} alt="" data-index="8"/>
              </li>
              <li className="glide__slide">
                <img src={slide_10} alt="" data-index="9"/>
              </li>
            </ul>
          </div>
          <div className="glide__arrows" data-glide-el="controls">
            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
          </div>
        </div>
      </div>
      
    </Layout>
  );

}

export default GamePage
